var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v("English Section")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"en.name","rules":"required","name":"The Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"name","error":failed ? errors[0]: null,"label":"Name","name":"name","fou":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"en.type","rules":"required","name":"The Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"type","size":"large","filterable":"","clearable":"","placeholder":"Record Type","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Record Type',"list":_vm.builderData.recordingTypes,"listItemLabel":'name',"listItemValue":'value'},on:{"change":_vm.emptyRecord},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})]}}])})],1)]),_c('div',{staticClass:"row"},[(_vm.formData.type === 'AUDIO')?_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"en.media_type","rules":"","name":"The Audio Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"media_type","size":"large","filterable":"","clearable":"","placeholder":"Media Type","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Media Type',"list":_vm.builderData.mediaTypes,"listItemLabel":'name',"listItemValue":'value'},on:{"change":_vm.emptyRecord},model:{value:(_vm.formData.media_type),callback:function ($$v) {_vm.$set(_vm.formData, "media_type", $$v)},expression:"formData.media_type"}})]}}],null,false,3243325433)})],1):_vm._e()]),_c('div',{staticClass:"row"},[(_vm.formData.type === 'VIDEO')?_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"en.record","rules":"required","name":"The Video URL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"record","error":failed ? errors[0]: null,"label":"Video URL","name":"record","fou":""},model:{value:(_vm.formData.record),callback:function ($$v) {_vm.$set(_vm.formData, "record", $$v)},expression:"formData.record"}})]}}],null,false,2956558269)})],1):_vm._e(),(_vm.formData.type === 'AUDIO' && _vm.formData.media_type == 'UPLOADED')?_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Audio")]),_c('el-tooltip',{attrs:{"placement":"right"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" (Audio only) ")]),_c('span',{staticStyle:{"margin":"0 5px"}},[_c('svg',{staticClass:"bi bi-question-circle",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"}}),_c('path',{attrs:{"d":"M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"}})])])]),_c('single-file-uploader-file-manager',{attrs:{"preview-width":'200px',"preview-height":'200px',"fileType":'audio',"accepted-files":_vm.audioExtensions},model:{value:(_vm.formData.record),callback:function ($$v) {_vm.$set(_vm.formData, "record", $$v)},expression:"formData.record"}})],1)]):_vm._e()]),(_vm.formData.type === 'AUDIO' && _vm.formData.media_type == 'LINK')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"en.link","rules":"required","name":"The Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"link","error":failed ? errors[0]: null,"label":"Link","name":"link","fou":""},model:{value:(_vm.formData.record),callback:function ($$v) {_vm.$set(_vm.formData, "record", $$v)},expression:"formData.record"}})]}}],null,false,4289142183)})],1)]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }